import { gql } from "@apollo/client";
import WbCloudyIcon from "@mui/icons-material/WbCloudy";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import React from "react";
import { FormattedDate } from "react-intl";
import { useViewerQuery } from "../hooks/viewer";
import { Weather as WeatherType } from "../types";

const GET_WEATHER = gql`
  query GetWeather($viewerName: String!) {
    weather(viewerName: $viewerName) {
      id
      timestamp
      name
      temperature
      date
    }
  }
`;

export default function Weather() {
  const { data } = useViewerQuery(GET_WEATHER);
  const weathers: WeatherType[] = data ? data.weather : [];
  return (
    <WeatherCard
      weather={weathers.length > 0 ? weathers[weathers.length - 1] : null}
    />
  );
}

function WeatherCard(props: { weather: WeatherType | null }) {
  let Icon: typeof WbCloudyIcon | null = null;
  if (props.weather && props.weather.name === "cloudy") {
    Icon = WbCloudyIcon;
  } else if (props.weather && props.weather.name === "sunny") {
    Icon = WbSunnyIcon;
  }
  const date = props.weather ? new Date(props.weather.date) : null;
  return (
    <Paper
      sx={{
        mb: 2,
        py: 1,
        px: 1,
        textAlign: "center",
        width: "16em",
        mx: "auto",
      }}
    >
      <Box>
        {Icon && (
          <Icon
            sx={{ fontSize: 50, mr: 2, verticalAlign: "middle" }}
            color="action"
          />
        )}
        <Typography
          variant="h4"
          component="span"
          sx={{ verticalAlign: "middle" }}
        >
          {props.weather ? (
            props.weather.temperature
          ) : (
            <Skeleton
              variant="circular"
              width="1em"
              height="1em"
              sx={{ display: "inline-block", verticalAlign: "middle" }}
            />
          )}
          °C
        </Typography>
      </Box>
      <Typography variant="h6" sx={{ mt: 1 }}>
        {date ? <FormattedDate value={date} dateStyle="full" /> : <Skeleton />}
      </Typography>
    </Paper>
  );
}
