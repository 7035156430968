import SendIcon from "@mui/icons-material/Send";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import * as React from "react";

export default function SendBar() {
  return (
    <FormControl sx={{ width: "100%", pb: 0.5 }} variant="outlined">
      <OutlinedInput
        value="Message"
        disabled
        endAdornment={
          <InputAdornment position="end">
            <SendIcon />
          </InputAdornment>
        }
        inputProps={{
          "aria-label": "send",
        }}
        size="small"
      />
    </FormControl>
  );
}
