import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ChaptersChoice from "./components/ChaptersChoice";
import DataLoader from "./components/DataLoader";
import Home from "./components/Home";
import TopBar from "./components/TopBar";
import { settings } from "./config";

const clientCache = new InMemoryCache({
  typePolicies: {
    Conversation: { merge: false },
    Message: { merge: false },
    DailyNews: { merge: false },
  },
});

const client = new ApolloClient({
  uri: settings.GRAPHQL_URL,
  cache: clientCache,
});

const theme = createTheme();

function App() {
  React.useEffect(() => {
    window.document.title = "Les Liaisons Élémentaires";
  }, []);

  return (
    <ApolloProvider client={client}>
      <IntlProvider locale="fr" defaultLocale="fr" timeZone="UTC">
        <ThemeProvider theme={theme}>
          <Router>
            <Container fixed maxWidth="xs" sx={{ px: 0 }}>
              <Switch>
                <Route exact path="/">
                  <TopBar />
                  <Home />
                </Route>
                <Route exact path="/v/:vid/">
                  <TopBar />
                  <ChaptersChoice />
                </Route>
                <Route path="/v/:vid/:chapter/">
                  <DataLoader />
                </Route>
              </Switch>
            </Container>
          </Router>
        </ThemeProvider>
      </IntlProvider>
    </ApolloProvider>
  );
}

export default App;
