import { gql } from "@apollo/client";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import React from "react";
import { useViewerQuery } from "../hooks/viewer";
import { DailyNews as DailyNewsType } from "../types";

const GET_DAILYNEWS = gql`
  query GetDailyNews($viewerName: String!) {
    dailyNews(viewerName: $viewerName) {
      id
      timestamp
      title
      content
    }
  }
`;

export default function InfoBar() {
  const { data } = useViewerQuery(GET_DAILYNEWS);

  const news: DailyNewsType | null = data && data.dailyNews;
  return (
    <Paper sx={{ m: 1 }}>
      <List disablePadding>
        <ListItem disablePadding>
          <ListItemAvatar sx={{ textAlign: "center" }}>
            <InfoIcon fontSize="large" color="info" />
          </ListItemAvatar>
          <Info news={news} />
        </ListItem>
      </List>
    </Paper>
  );
}

const Info = (props: { news: DailyNewsType | null }) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setOpen(false);
  };

  const text = (
    <ListItemText
      primary={<Typography variant="body1">L'info du jour</Typography>}
      secondary={
        props.news ? (
          <Typography variant="body2" color="text.secondary">
            {props.news.title}
          </Typography>
        ) : (
          <Skeleton />
        )
      }
    />
  );

  if (!props.news || !props.news.content) {
    return <Box sx={{ my: 0.5, mx: 2, flexGrow: 1 }}>{text}</Box>;
  } else {
    return (
      <ListItemButton onClick={handleClickOpen} dense>
        {text}
        <Dialog
          open={open}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">{props.news.title}</DialogTitle>
          <DialogContent dividers>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
              sx={{ whiteSpace: "pre-line" }}
            >
              {props.news.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
      </ListItemButton>
    );
  }
};
