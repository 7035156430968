import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FormattedTime } from "react-intl";
import { Message as MessageType } from "../types";
import { convertEmojis } from "../utils";
import AttachmentAudios from "./AttachmentAudios";
import AttachmentImages from "./AttachmentImages";
import CharacterAvatar from "./CharacterAvatar";
import MessageResponse from "./MessageResponse";

interface Props {
  message: MessageType;
}

const Message = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
      <Paper elevation={2} sx={{ ml: 3, mr: 1.5, mb: 2, p: 1 }} ref={ref}>
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            marginLeft: "-1.5em",
          }}
        >
          <CharacterAvatar character={props.message.author} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              ml: 0.5,
            }}
          >
            <MessageResponse message={props.message} />
            <AttachmentImages message={props.message} />
            <AttachmentAudios message={props.message} />
            <Typography
              component="p"
              variant="body2"
              sx={{ flexGrow: 1, whiteSpace: "pre-line" }}
            >
              {props.message.content
                ? convertEmojis(props.message.content)
                : ""}
            </Typography>
            <Typography
              variant="caption"
              component="div"
              sx={{
                color: "gray",
                lineHeight: 1,
                mb: -0.5,
                mr: -0.5,
                textAlign: "right",
              }}
            >
              <FormattedTime
                value={new Date(props.message.dateSent)}
                hour="numeric"
                minute="numeric"
              />
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  }
);

export default Message;
