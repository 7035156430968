import NewMessageIcon from "@mui/icons-material/NewReleases";
import AvatarGroup from "@mui/material/AvatarGroup";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { FormattedDate } from "react-intl";
import { Link, useRouteMatch } from "react-router-dom";
import { Conversation as ConversationType } from "../types";
import CharacterAvatar from "./CharacterAvatar";

interface Props {
  conversation: ConversationType;
  isFirst: boolean;
}

export default function ConversationSummary(props: Props) {
  const { url } = useRouteMatch();
  const lastMessage =
    props.conversation.messages[props.conversation.messages.length - 1];
  const avatarList = props.conversation.members.map((m) => (
    <CharacterAvatar
      key={m.id}
      character={m}
      sx={props.conversation.members.length > 2 ? { mr: 0 } : undefined}
    />
  ));
  return (
    <ListItem alignItems="flex-start" sx={{ px: 1, pb: 0.5, pt: 0.5 }}>
      <Paper sx={{ width: "100%" }}>
        <ListItemButton component={Link} to={`${url}${props.conversation.id}`}>
          {avatarList.length > 2 ? (
            <AvatarGroup sx={{ mr: 1 }} max={4}>
              {avatarList}
            </AvatarGroup>
          ) : (
            <ListItemAvatar sx={{ mr: 2, display: "flex" }}>
              {avatarList}
            </ListItemAvatar>
          )}
          <ListItemText
            primary={props.conversation.name}
            secondary={
              <>
                <FormattedDate
                  value={new Date(lastMessage.dateSent)}
                  year="numeric"
                  month="numeric"
                  day="numeric"
                  hour="numeric"
                  minute="numeric"
                />
                {/*
                {" — "}
                {ellipsis(lastMessage.content, 35)}
              {" — "}
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {lastMessage.author.name}
              </Typography>
              {" — "}
              {ellipsis(lastMessage.content, 100)}
              */}
              </>
            }
          />
          {!lastMessage.seen && (
            <NewMessageIcon fontSize="small" color="action" />
          )}
        </ListItemButton>
      </Paper>
    </ListItem>
  );
}
