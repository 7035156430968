import { gql, useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import React from "react";
import { useHistory } from "react-router-dom";

const CREATE_VIEWER = gql`
  mutation CreateViewer {
    createViewer {
      id
      name
      progressTimestamp
    }
  }
`;

export default function Home() {
  const history = useHistory();
  const [createViewer, { error }] = useMutation(CREATE_VIEWER);
  if (error) {
    return null;
  }
  const onButtonClick = () => {
    createViewer().then((result) => {
      const name = result.data.createViewer.name;
      history.push(`/v/${name}/`);
    });
  };
  return (
    <Paper sx={{ mt: 3, px: 2, py: 1 }}>
      <p>Bienvenue sur Les Liaisons Elementaires.</p>
      <p style={{ textAlign: "center", marginTop: "2em" }}>
        <Button onClick={onButtonClick} variant="outlined">
          Démarrer l'histoire
        </Button>
      </p>
    </Paper>
  );
}
