import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import * as React from "react";
import { settings } from "../config";
import { Attachment, Message } from "../types";

interface Props {
  message: Message;
}

const AttachmentImages = (props: Props) => {
  const images = props.message.attachments.filter(
    (a) => a.contentType === "image"
  );
  if (images.length === 0) {
    return null;
  }
  return (
    <ImageList variant="masonry" cols={images.length === 1 ? 1 : 2} gap={8}>
      {images.map((image) => (
        <AttachmentImage key={image.url} {...image} />
      ))}
    </ImageList>
  );
};

export default AttachmentImages;

const AttachmentImage = (props: Attachment) => {
  const url = `${settings.API_URL}${props.url}`;
  return (
    <ImageListItem>
      <img
        src={`${url}?w=248&fit=crop&auto=format`}
        srcSet={`${url}?w=248&fit=crop&auto=format&dpr=2 2x`}
        alt={props.name}
        loading="lazy"
      />
    </ImageListItem>
  );
};
