import ReplyIcon from "@mui/icons-material/Reply";
import { blueGrey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Message } from "../types";
import { ellipsis } from "../utils";

interface Props {
  message: Message;
}

const MessageResponse = (props: Props) => {
  if (!props.message.repliesTo) {
    return null;
  }
  const summary = props.message.repliesTo.content
    ? " : " + ellipsis(props.message.repliesTo.content, 70)
    : "";
  return (
    <Typography
      variant="body2"
      component="div"
      bgcolor={blueGrey[100]}
      sx={{
        px: 1,
        py: 0.5,
        mb: 1,
        fontSize: "0.8rem",
        borderTopLeftRadius: "0.3rem",
        borderTopRightRadius: "0.3rem",
      }}
    >
      <ReplyIcon
        sx={{ verticalAlign: "text-bottom", fontSize: "1.2rem", mr: 0.3 }}
      />
      Réponse à {props.message.repliesTo.author.name}
      {summary}
    </Typography>
  );
};

export default MessageResponse;
