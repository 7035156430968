import { useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import React from "react";
import {
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useConversations, useMessagesMetadata } from "../hooks/conversations";
import { useViewer } from "../hooks/viewer";
import { SET_VIEWER_PROGRESS } from "../queries";
import {
  Conversation as ConversationType,
  Message,
  MessagesMetadata,
  Viewer,
} from "../types";
import ChapterTopBar from "./ChapterTopBar";
import ConvSkel from "./ConvSkel";
import Conversation from "./Conversation";
import Conversations from "./Conversations";
import InfoBar from "./InfoBar";
import ProgressBar from "./ProgressBar";
import Weather from "./Weather";

const getLastMessage = (conversations: ConversationType[]): Message | null => {
  let msg: Message | null = null;
  conversations.forEach((c) => {
    c.messages.forEach((m) => {
      if (msg === null) {
        msg = m;
      }
      if (m.timestamp >= msg.timestamp) {
        msg = m;
      }
    });
  });
  return msg;
};

const getNextMessageAt = (
  lastMessage: Message | null,
  messagesMetadata: MessagesMetadata | undefined
) => {
  if (lastMessage) {
    return lastMessage.timestamp + lastMessage.nextIn;
  }
  if (messagesMetadata) {
    return messagesMetadata.timestamps[0].timestamp;
  }
};

export default function DataLoader() {
  const [playing, setPlaying] = React.useState(false);
  const { path } = useRouteMatch();
  // const [progress, setProgress] = React.useState(0);

  const { viewer, error: getViewerError, loading: viewerLoading } = useViewer();
  const viewerName = viewer ? viewer.name : null;

  const [setViewerProgress, { loading: setProgressLoading }] =
    useMutation(SET_VIEWER_PROGRESS);

  const { data: messagesMetadata } = useMessagesMetadata();
  const { conversations, error, loading: convLoading } = useConversations();

  const lastMessage = getLastMessage(conversations);
  const nextPollIn = lastMessage ? lastMessage.nextIn : 2;
  const nextProgress = (viewer ? viewer.progressTimestamp : 0) + nextPollIn;

  const setProgress = React.useCallback(
    async (progress: number) => {
      await setViewerProgress({
        variables: { name: viewerName, progress },
      });
    },
    [viewerName, setViewerProgress]
  );

  const skipToNextMessage = () => {
    const nextMessageAt = getNextMessageAt(lastMessage, messagesMetadata);
    if (!nextMessageAt) {
      return;
    }
    setProgress(nextMessageAt);
  };

  React.useEffect(() => {
    if (!playing) {
      return;
    }
    const timeout = setTimeout(setProgress, nextPollIn * 1000, nextProgress);
    return () => clearTimeout(timeout);
  }, [
    playing,
    nextPollIn,
    setProgress,
    nextProgress,
    viewerName,
    lastMessage?.id,
  ]);

  if (error || getViewerError) return <p>Error :(</p>;

  return (
    <>
      <ChapterTopBar
        conversations={conversations}
        loading={viewerLoading || convLoading || setProgressLoading}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          marginTop: "-64px",
          //marginTop: "-56px",
          // "@media (min-width:0px) and (orientation: landscape)": {
          //   marginTop: "-48px",
          // },
          // "@media (min-width:600px)": {
          //   marginTop: "-64px",
          // },
        }}
      >
        <div style={{ minHeight: "64px" }} />
        <Switch>
          <Route exact path={path}>
            <InfoBar />
            <Weather />
            <Conversations
              conversations={conversations}
              loading={viewerLoading || convLoading}
            />
          </Route>
          <Route path={`${path}:conv`}>
            <ConversationWrapper
              playing={playing}
              conversations={conversations}
              viewer={viewer}
              loading={convLoading}
            />
          </Route>
        </Switch>
        <ProgressBar
          playing={playing}
          setPlaying={setPlaying}
          setProgress={setProgress}
          skipToNextMessage={skipToNextMessage}
        />
      </Box>
    </>
  );
}

type ParamsType = { vid: string; chapter: string; conv: string };
type CWProps = {
  conversations: ConversationType[];
  playing: boolean;
  viewer?: Viewer;
  loading: boolean;
};

function ConversationWrapper(props: CWProps) {
  const { conv } = useParams<ParamsType>();
  if (props.loading) {
    return (
      <Paper elevation={0} sx={{ my: 3 }}>
        <ConvSkel />
        <ConvSkel />
        <ConvSkel />
      </Paper>
    );
  }
  const filteredConversations = props.conversations.filter(
    (c) => c.id === conv
  );
  if (filteredConversations.length === 0) {
    return (
      <Alert severity="error">
        Conversation inconnue, <Link to=".">revenez au départ SVP</Link>.
      </Alert>
    );
  }
  return (
    <Conversation
      conversation={filteredConversations[0]}
      playing={props.playing}
    />
  );
}
