import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
//import SyncIcon from "@mui/icons-material/Sync";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { orange } from "@mui/material/colors";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Link } from "react-router-dom";
import { settings } from "../config";
import { useViewer } from "../hooks/viewer";
import SyncIcon from "./sync-bar.gif";

interface Props {
  notifications?: number;
  loading?: boolean;
}

export default function TopBar(props: Props) {
  const { viewer } = useViewer();
  //const { chapter } = useParams<{ chapter?: string }>();
  let homeLink = "/";
  if (viewer) {
    homeLink += `v/${viewer.name}/`;
    // if (chapter) {
    //   homeLink += `/${chapter}/`;
    // }
  }
  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="h6"
              component={Link}
              to={homeLink}
              sx={{ verticalAlign: "middle", mr: 1, textDecoration: "none" }}
              color="inherit"
            >
              <img
                src={`${settings.PUBLIC_URL}/favicon-32x32.png`}
                alt="logo"
                style={{
                  verticalAlign: "bottom",
                  marginRight: "0.4rem",
                  backgroundColor: "#ffffff",
                  borderRadius: "10%",
                }}
              />
              Les Liaisons Élémentaires
            </Typography>
          </Box>
          <img
            src={SyncIcon}
            alt="…"
            style={{
              color: "inherit",
              marginRight: "0.5em",
              visibility: props.loading ? "visible" : "hidden",
            }}
          />
          {viewer ? (
            props.notifications && props.notifications > 0 ? (
              <NotificationImportantIcon sx={{ color: orange[500] }} />
            ) : (
              <NotificationsNoneIcon color="inherit" />
            )
          ) : null}
        </Toolbar>
      </AppBar>
    </>
  );
}
