import { useQuery } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import React from "react";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { useMessagesMetadata } from "../hooks/conversations";
import { useViewer } from "../hooks/viewer";
import { GET_CHAPTERS } from "../queries";
import { Chapter } from "../types";
import PlayButton, { Props as PlayButtonProps } from "./PlayButton";
import SkipButton, { Props as SkipButtonProps } from "./SkipButton";

interface Props extends PlayButtonProps, SkipButtonProps {
  setProgress: (progress: number) => void;
}

export default function ProgressBar(props: Omit<Props, "disabled">) {
  const intl = useIntl();
  const { chapter: chapterStr } = useParams<{ chapter: string }>();
  const chapter = parseInt(chapterStr);
  const { viewer } = useViewer();
  const currentTimestamp = viewer ? viewer.progressTimestamp : 0;
  const [value, setValue] = React.useState<number>(currentTimestamp);
  const { error, data } = useMessagesMetadata();
  const { error: chapterError, data: chapterData } = useQuery(GET_CHAPTERS);

  const timestamps = data ? data.timestamps : [];
  const marks = timestamps.map((tsData) => ({
    value: tsData.timestamp,
    label2: intl.formatDate(tsData.date, {
      month: "numeric",
      day: "numeric",
    }),
  }));
  const chapters: Chapter[] = chapterData ? chapterData.chapters : [];

  // Update the slider when the viewer timestamp changes
  React.useEffect(() => {
    setValue(currentTimestamp);
  }, [currentTimestamp]);

  // Stop when we reach the last message
  const atLastMessage =
    timestamps.length > 0 &&
    currentTimestamp >= timestamps[timestamps.length - 1].timestamp;
  const { setPlaying } = props;
  React.useEffect(() => {
    if (atLastMessage) {
      setPlaying(false);
    }
  }, [atLastMessage, setPlaying]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  // On mouseup, send the request
  const handleChangeCommitted = (
    event: Event | React.SyntheticEvent,
    newValue: number | number[]
  ) => {
    props.setProgress(newValue as number);
  };

  if (error && chapterError) {
    console.log(error, chapterError);
    return null;
  }

  if (chapters && chapters.map((c) => c.number).indexOf(chapter) === -1) {
    return (
      <Alert
        severity="error"
        action={
          <Button component={Link} color="inherit" size="small" to="..">
            Choisir un chapitre
          </Button>
        }
      >
        Ce chapitre n'existe pas !
      </Alert>
    );
  }

  const valueLabelFormat = (value: number) => {
    const lower = marks.filter((mark) => mark.value <= value);
    return lower.length === 0 ? "?" : lower[lower.length - 1].label2;
  };

  return (
    <>
      {atLastMessage && viewer && (
        <Alert
          severity="success"
          sx={{ mt: 1 }}
          action={
            chapter < Math.max(...chapters.map((c) => c.number)) && (
              <Button
                component={Link}
                color="inherit"
                size="small"
                to={`/v/${viewer.name}/${chapter + 1}`}
                sx={{ alignSelf: "center" }}
              >
                Chapitre&nbsp;{chapter + 1}
              </Button>
            )
          }
        >
          Vous avez atteint la fin du chapitre&nbsp;{chapter}&nbsp;!
        </Alert>
      )}
      <Paper sx={{ display: "flex", mt: 1, p: 1, pb: 0.5 }}>
        <Box display="flex">
          <PlayButton
            playing={props.playing}
            setPlaying={props.setPlaying}
            disabled={atLastMessage}
          />
          <SkipButton
            skipToNextMessage={props.skipToNextMessage}
            disabled={atLastMessage}
          />
        </Box>
        <Box sx={{ flexGrow: 1, pl: 3, pr: 1 }}>
          <Slider
            aria-label="Progress"
            value={value}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            step={10}
            valueLabelDisplay="auto"
            valueLabelFormat={valueLabelFormat}
            marks={marks}
            min={marks.length > 0 ? marks[0].value : 0}
            max={marks.length > 0 ? marks[marks.length - 1].value : 100}
            size="small"
            sx={{ verticalAlign: "middle" }}
          />
        </Box>
      </Paper>
    </>
  );
}
