import { styled } from "@mui/system";
import * as React from "react";
import { settings } from "../config";
import { Message } from "../types";

interface Props {
  message: Message;
}

const Audio = styled("audio", { name: "Audio" })({
  width: "100%",
  display: "block",
});

const AttachmentAudios = (props: Props) => {
  const attachments = props.message.attachments.filter(
    (a) => a.contentType === "audio"
  );
  if (attachments.length === 0) {
    return null;
  }
  return (
    <div>
      {attachments.map((attachment) => (
        <Audio
          key={attachment.url}
          controls
          src={`${settings.API_URL}${attachment.url}`}
          sx={{ my: 0.5 }}
        ></Audio>
      ))}
    </div>
  );
};

export default AttachmentAudios;
