import { gql } from "@apollo/client";

export const GET_VIEWER = gql`
  query GetViewer($name: String!) {
    viewer(name: $name) {
      id
      name
      progressTimestamp
    }
  }
`;

export const SET_VIEWER_PROGRESS = gql`
  mutation SetViewerProgress($name: String!, $progress: Int!) {
    setViewerProgress(name: $name, progress: $progress) {
      id
      progressTimestamp
    }
  }
`;

export const GET_CONVERSATIONS = gql`
  query GetConversations($viewerName: String!, $chapter: Int!) {
    conversations(viewerName: $viewerName, chapter: $chapter) {
      id
      name
      icon
      messages {
        id
        author {
          id
          name
          color
        }
        timestamp
        dateSent
        content
        seen
        nextTypingIn
        nextIn
        repliesTo {
          id
          author {
            id
            name
          }
          content
        }
        attachments {
          contentType
          size
          url
        }
        chapter {
          id
          number
        }
      }
      members {
        id
        name
        color
      }
    }
  }
`;

export const MARK_AS_READ = gql`
  mutation MarkAsRead(
    $viewerName: String!
    $chapter: Int!
    $conversationId: ID!
  ) {
    markAsRead(
      viewerName: $viewerName
      chapter: $chapter
      conversationId: $conversationId
    ) {
      id
      messages {
        id
        seen
      }
    }
  }
`;

export const GET_MESSAGES_METADATA = gql`
  query GetMessagesMetadata($chapter: Int!) {
    messagesMetadata(chapter: $chapter) {
      timestamps {
        timestamp
        date
      }
    }
  }
`;

export const GET_CHAPTERS = gql`
  query GetChapters {
    chapters {
      id
      name
      number
    }
  }
`;
