import Emoji from "emoji-name-map";

export function ellipsis(str: string, n: number) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substring(0, n - 1); // the original check
  return subString.substring(0, subString.lastIndexOf(" ")) + "…";
}

export function convertEmojis(str: string) {
  return str.replace(/:[^:\s]*(?:::[^:\s]*)*:/, (match) => {
    const emoji = Emoji.get(match);
    if (!emoji) {
      console.log("Could not convert emoji", match);
      return match;
    } else {
      return emoji;
    }
  });
}
