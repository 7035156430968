import * as React from "react";
import { useParams } from "react-router-dom";
import { Conversation } from "../types";
import TopBar from "./TopBar";

interface Props {
  conversations?: Conversation[];
  loading: boolean;
}

export default function ChapterTopBar(props: Props) {
  const { chapter } = useParams<{ chapter: string }>();
  const notifications = (props.conversations || [])
    .map(
      (c) =>
        c.messages.filter(
          (m) => m.chapter.number === parseInt(chapter) && !m.seen
        ).length
    )
    .reduce((prev, current) => prev + current, 0);
  return <TopBar notifications={notifications} loading={props.loading} />;
}
