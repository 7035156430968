import { gql, useQuery } from "@apollo/client";
import ChapterIcon from "@mui/icons-material/LibraryBooks";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Paper from "@mui/material/Paper";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Chapter } from "../types";
import SyncIcon from "./sync.gif";

const GET_CHAPTERS = gql`
  query GetChapters {
    chapters {
      id
      number
      name
    }
  }
`;

export default function ChaptersChoice() {
  const { url } = useRouteMatch();
  const { error, data, loading } = useQuery(GET_CHAPTERS);
  if (error) {
    return null;
  }
  const chapters: Chapter[] = data ? data.chapters : [];
  return (
    <Paper sx={{ mt: 3, px: 2, py: 1 }}>
      <p style={{ marginBottom: 0 }}>Choississez le chapitre :</p>
      {loading ? (
        <Box sx={{ mx: 3, my: 3 }}>
          <img src={SyncIcon} alt="…" />
        </Box>
      ) : (
        <List>
          {chapters.map((c) => (
            <ListItem key={c.id} disableGutters disablePadding>
              <ListItemButton component={Link} to={`${url}${c.number}/`}>
                <ChapterIcon sx={{ mr: 1 }} /> Chapitre {c.number}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Paper>
  );
}
