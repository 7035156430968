import { useMutation } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import React from "react";
import { FormattedDate } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { useViewer } from "../hooks/viewer";
import { MARK_AS_READ } from "../queries";
import {
  Conversation as ConversationType,
  Message as MessageType,
} from "../types";
import CharacterAvatar from "./CharacterAvatar";
import Message from "./Message";
import SendBar from "./SendBar";
import Typing from "./typing.gif";

interface Props {
  conversation: ConversationType;
  playing: boolean;
}

export default function Conversation(props: Props) {
  const { viewer } = useViewer();
  const { chapter } = useParams<{ chapter: string }>();

  const [showTyping, setShowTyping] = React.useState(false);
  const lastMessage =
    props.conversation.messages[props.conversation.messages.length - 1];

  const lastMessageNextTypingIn = lastMessage.nextTypingIn,
    lastMessageTimestamp = lastMessage.timestamp,
    viewerProgressTimestamp = viewer ? viewer.progressTimestamp : null;
  React.useEffect(() => {
    if (
      !viewerProgressTimestamp ||
      lastMessageNextTypingIn === null ||
      !props.playing
    ) {
      setShowTyping(false);
      return;
    }
    const waitSeconds =
      lastMessageNextTypingIn -
      (viewerProgressTimestamp - lastMessageTimestamp);
    //console.log("Showing typing indicator in:", waitSeconds);
    if (waitSeconds > 0) {
      setShowTyping(false);
    }
    const timeout = setTimeout(() => {
      setShowTyping(true);
    }, Math.max(waitSeconds, 0) * 1000);
    return () => clearTimeout(timeout);
  }, [
    lastMessageNextTypingIn,
    lastMessageTimestamp,
    viewerProgressTimestamp,
    props.playing,
  ]);

  const firstUnreadRef = React.useRef<null | HTMLDivElement>(null);
  const scrollToUnread = () => {
    firstUnreadRef.current?.scrollIntoView({ block: "center" });
  };
  const listRef = React.useRef<null | HTMLUListElement>(null);

  // Serialize so we only scroll when there's a new message
  const msgIds = props.conversation.messages.map((m) => m.id).join();
  React.useEffect(() => {
    const scrolling =
      listRef &&
      listRef.current &&
      listRef.current.scrollTop !== 0 && // when we open the conv, we want to scroll
      listRef.current.scrollHeight -
        listRef.current.scrollTop -
        listRef.current.clientHeight >
        150;
    if (!scrolling) {
      scrollToUnread();
    }
  }, [msgIds, showTyping]);

  // Mark messages as read
  const viewerName = viewer ? viewer.name : null;
  const unreadCount = props.conversation.messages.filter((m) => !m.seen).length;
  const [markAsRead] = useMutation(MARK_AS_READ, {
    variables: {
      viewerName,
      chapter: parseInt(chapter),
      conversationId: props.conversation.id,
    },
  });
  React.useEffect(() => {
    if (!viewerName) {
      return;
    }
    if (unreadCount > 0) {
      markAsRead();
    }
  }, [msgIds, viewerName, markAsRead, unreadCount]);

  return (
    <>
      <Box sx={{ display: "flex", pt: 1, pb: 0.5 }}>
        <Button
          color="inherit"
          component={Link}
          to="."
          sx={{ alignSelf: "end", position: "absolute" }}
        >
          <ArrowBackIcon />
        </Button>
        <Box sx={{ textAlign: "center", width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {props.conversation.members.map((m) => (
              <CharacterAvatar key={m.id} character={m} />
            ))}
          </Box>
          {props.conversation.members.length > 2 && (
            <Typography variant="subtitle1" component="div">
              {props.conversation.name}
            </Typography>
          )}
          <Typography variant="h6" component="div">
            <FormattedDate
              value={new Date(lastMessage.dateSent)}
              dateStyle="long"
            />
          </Typography>
        </Box>
      </Box>
      <List
        sx={{
          width: "100%",
          bgcolor: "grey.200",
          overflow: "auto",
          flexGrow: 1,
        }}
        ref={listRef}
      >
        {(props.conversation.messages as MessageType[]).map(
          (m: MessageType, index: number) => {
            const firstUnread =
              props.conversation.messages.length === 1 ||
              (index > 1 &&
                props.conversation.messages[index - 1].seen &&
                !m.seen);
            return (
              <Message
                key={m.id}
                message={m}
                ref={firstUnread ? firstUnreadRef : undefined}
              />
            );
          }
        )}
        {showTyping && (
          <Box sx={{ textAlign: "right", paddingRight: 5 }}>
            <img src={Typing} alt="…" width={32} height={8} />
          </Box>
        )}
        {/* No unread messages: scroll to bottom */}
        {unreadCount === 0 && <div ref={firstUnreadRef} />}
      </List>
      <SendBar />
    </>
  );
}
