import SkipNextIcon from "@mui/icons-material/SkipNext";
import IconButton from "@mui/material/IconButton";
import React from "react";

export interface Props {
  skipToNextMessage: () => void;
  disabled?: boolean;
}

export default function SkipButton(props: Props) {
  return (
    <IconButton
      onClick={props.skipToNextMessage}
      disabled={!!props.disabled}
      size="small"
    >
      <SkipNextIcon fontSize="small" />
    </IconButton>
  );
}
