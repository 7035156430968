import { SxProps } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import * as React from "react";
import { Character as CharacterType } from "../types";

interface Props {
  character: CharacterType;
  size?: number;
  sx?: SxProps;
}

export default function CharacterAvatar(props: Props) {
  const sx = {
    bgcolor: props.character.color || undefined,
    mr: 0.5,
    width: props.size,
    height: props.size,
    ...props.sx,
  };
  return (
    <Avatar
      key={props.character.name}
      alt={props.character.name}
      title={props.character.name}
      sx={sx}
    >
      {props.character.name.substring(0, 3)}
    </Avatar>
  );
}
