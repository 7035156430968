import { DocumentNode, useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import { GET_VIEWER } from "../queries";
import { Viewer } from "../types";

export function useViewer() {
  const { vid } = useParams<{ vid: string }>();
  const { error, data, loading } = useQuery(GET_VIEWER, {
    variables: { name: vid },
    skip: !vid,
  });
  const viewer: Viewer | undefined = data ? data.viewer : undefined;
  return { data, error, loading, viewer };
}

export function useViewerQuery(
  query: DocumentNode,
  variables: Record<string, any> | null = null
) {
  const { viewer } = useViewer();
  const { error, data, previousData, loading, refetch } = useQuery(query, {
    variables: {
      viewerName: viewer?.name,
      ...(variables || {}),
    },
    skip: !viewer,
  });

  React.useEffect(() => {
    if (!viewer) {
      return;
    }
    refetch();
  }, [refetch, viewer, viewer?.progressTimestamp]);

  return {
    data: !error && data ? data : previousData || null,
    error,
    loading,
    refetch,
  };
}
