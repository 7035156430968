import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import IconButton from "@mui/material/IconButton";
import React from "react";

export interface Props {
  playing: boolean;
  setPlaying: (value: boolean) => void;
  disabled?: boolean;
}

export default function PlayButton(props: Props) {
  const onClick = () => {
    if (props.playing) {
      props.setPlaying(false);
    } else {
      props.setPlaying(true);
    }
  };
  return (
    <IconButton
      onClick={onClick}
      color="primary"
      disabled={!!props.disabled}
      size="small"
    >
      {props.playing ? (
        <PauseCircleIcon fontSize="small" />
      ) : (
        <PlayCircleIcon fontSize="small" />
      )}
    </IconButton>
  );
}
