import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_CONVERSATIONS, GET_MESSAGES_METADATA } from "../queries";
import { Conversation, MessagesMetadata } from "../types";
import { useViewerQuery } from "./viewer";

export function useConversations() {
  const { chapter } = useParams<{ vid: string; chapter: string }>();
  const { error, data, loading, refetch } = useViewerQuery(GET_CONVERSATIONS, {
    chapter: parseInt(chapter),
  });

  let conversations: Conversation[] = data ? data.conversations : [];

  if (conversations.length > 1) {
    // Make a copy because Apollo results are immutable
    conversations = conversations.slice();
    conversations.sort(
      (c1, c2) =>
        Math.max(...c2.messages.map((m) => m.timestamp)) -
        Math.max(...c1.messages.map((m) => m.timestamp))
    );
  }
  return { data, error, loading, refetch, conversations };
}

export function useMessagesMetadata() {
  const { chapter } = useParams<{ vid: string; chapter: string }>();
  const { error, data, loading } = useQuery<{
    messagesMetadata: MessagesMetadata;
  }>(GET_MESSAGES_METADATA, {
    variables: { chapter: parseInt(chapter) },
  });
  return { data: data?.messagesMetadata, error, loading };
}
