import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import * as React from "react";
import { Conversation as ConversationType } from "../types";
import ConversationSummary from "./ConversationSummary";
import ConvSkel from "./ConvSkel";

interface Props {
  conversations: ConversationType[];
  loading: boolean;
}

export default function Conversations(props: Props) {
  if (props.loading) {
    return (
      <Box sx={{ mb: 3 }}>
        <ConvSkel />
        <ConvSkel />
        <ConvSkel />
      </Box>
    );
  }
  if (props.conversations.length === 0) {
    return (
      <Alert severity="info" sx={{ m: 2 }}>
        Pas de conversations pour l'instant. Appuyez sur "Play" en bas à gauche
        pour démarrer.
      </Alert>
    );
  }
  return (
    <Box sx={{ overflow: "auto" }}>
      <List sx={{ width: "100%", bgcolor: "background.paper", p: 0 }}>
        {(props.conversations as ConversationType[]).map((c, index) => (
          <ConversationSummary
            key={c.id}
            conversation={c}
            isFirst={index === 0}
          />
        ))}
      </List>
    </Box>
  );
}
